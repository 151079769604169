import { Checkbox, Select } from 'antd'
import axios from 'axios'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import styled, { css, keyframes } from 'styled-components'
import { BASE_V2_URL } from '../../api'
import { hasReportsPermissionSelector } from '../../redux/selectors/user'
import { cssvar } from '../../styles/var'
import Dialog from '../Dialog'

const PDFGenerator = ({ id, navDrilldownHeader, filters }) => {
  const hasReportsPermission = useSelector(hasReportsPermissionSelector)
  if (!hasReportsPermission) return null

  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState(false)
  const [progressDialog, setProgressDialog] = useState(false)

  const [includeOngoing, setIncludeOngoing] = useState(false)
  const [selectedSeason, setSelectedSeason] = useState(undefined)

  const { t, i18n } = useTranslation()

  const seasons = navDrilldownHeader.data.seasons

  async function downloadPDF() {
    setLoading(true)
    setProgressDialog(true)
    try {
      const result = await axios(
        `${BASE_V2_URL}/players/${id}/report`,
        {
          params: {
            lang: (i18n.language).slice(0, 2),
            ...(showSelectSeason && { timeframe: selectedSeason }),
            ...(includeOngoing && { ongoing_injuries: true }),
          },
        },
      )
      setUrl(result.data.url)
      setLoading(false)
      window.open(result.data.url, '_blank')
    } catch (err) {
      console.log('Unable to load report for ', id)
    }
  }

  function askConfirmation() {
    setConfirmDialog(true)
  }

  function handleConfirmation() {
    setConfirmDialog(false)
    downloadPDF()
  }

  const showSelectSeason = !['national_team', 'profile'].includes(navDrilldownHeader.data.type)

  return (
    <Fragment>
      <Button onClick={askConfirmation}>{t('pdf-report-download')}</Button>

      {/* Confirmation Dialog */}
      <Dialog
        open={confirmDialog}
        title={t('pdf-report-confirm-title')}
        onClose={() => setConfirmDialog(false)}
        closable
        dialogStyle="width: 100%; max-width: 500px;"
      >
        <ConfirmationDialogContent>
          <DialogTitle>{t('pdf-report-confirm-message')}</DialogTitle>
          {showSelectSeason && (
            <Select
              options={seasons.map(season => ({ label: season.label, value: season.value }))}
              style={{ width: 200 }}
              onChange={setSelectedSeason}
              value={selectedSeason}
              placeholder={t('select_season')}
            >
              {seasons.map(season => <Select.Option value={season.value}>{season.label}</Select.Option>)}
            </Select>
          )}
          {
            ['competition'].includes(navDrilldownHeader.data.type) && (
              <Checkbox checked={includeOngoing} onChange={() => setIncludeOngoing(!includeOngoing)}>{t('pdf-report-include-ongoing')}</Checkbox>
            )
          }
          <DialogActions>
            <Button onClick={() => setConfirmDialog(false)}>{t('pdf-report-cancel')}</Button>
            <Button onClick={handleConfirmation}>
              {t('pdf-report-confirm')}
            </Button>
          </DialogActions>
        </ConfirmationDialogContent>
      </Dialog>

      {/* Progress Dialog */}
      <Dialog
        open={progressDialog}
        title={t('pdf-report-generator-title')}
        onClose={() => setProgressDialog(false)}
        closable={!loading}
        dialogStyle="width: 100%; max-width: 500px;">
        <PDFPicture>
          <TitlePage loaded={!loading}>PDF</TitlePage>
          <SecondPage loaded={!loading} />
          {loading && (
            <AnimatedPage>
              <Circle />
              <Lines>
                <div />
                <div />
                <div />
                <div />
                <div />
              </Lines>
            </AnimatedPage>
          )}
        </PDFPicture>
        {
          loading
            ? (
              <Fragment>
                <DialogTitle>{t('pdf-report-preparing')}</DialogTitle>
                <DialogSubtitle>{t('pdf-report-preparing-desc')}</DialogSubtitle>
              </Fragment>
            )
            : (
              <Fragment>
                <DialogTitle>{t('pdf-report-ready')}</DialogTitle>
                <DialogSubtitle>
                  <a href={url} target="_blank" rel="noreferrer">{t('pdf-report-ready-click-here')}</a>
                  {' '}
                  {t('pdf-report-ready-desc')}
                </DialogSubtitle>
              </Fragment>
            )
        }
      </Dialog>
    </Fragment>
  )
}

const mapStateToProps = ({ filters, navDrilldownHeader }) => ({
  filters,
  navDrilldownHeader,
})

export default connect(mapStateToProps)(PDFGenerator)

const Button = styled.div`
  padding: 7px 12px;
  color: ${cssvar('primaryColor')};
  border: 1px solid ${cssvar('primaryColor')};
  font-weight: 550;
  white-space: nowrap;
  border-radius: 4px;
  cursor: pointer;
  transition: .3s;
  &:hover {
    background-color: #f7f7f7;
    color: ${cssvar('primaryHoverColor')};
    border-color: ${cssvar('primaryHoverColor')};
  }
  @media (max-width: 1024px) {
    padding: 5px 12px;
  }
`

const PDFPicture = styled.div`
  width: 90px;
  height: 60px;
  margin: 15px auto;
  position: relative;
`
const TitlePage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: 3px solid ${cssvar('mainColor')};
  border-radius: 8px;
  font-weight: 700;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  transition: .4s;
  ${props => props.loaded && css`
    background-color: #52b788;
    color: #fff;
    border-color: #40916c;
  `}
`
const SecondPage = styled(TitlePage)`
  transform: translateY(-9px) scale(.85);
  transform-origin: center;
  z-index: 2;
  ${props => props.loaded && css`
    background-color: #fff;
    border-color: #40916c;
  `}
`
const animation = keyframes`
  from {
    transform: translateY(-200%) scale(.85);
  }
  60% {
    transform: translateY(-9px) scale(.85);
  }
`
const AnimatedPage = styled(SecondPage)`
  animation: ${animation} 3s ease-out infinite;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  padding: 5px;
  background-color: #fafafa;
  z-index: 1;
`
const Circle = styled.div`
  flex: 0 0 auto;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #ccc;
`
const Lines = styled.div`
  flex: 1 1 auto;
  div {
    height: 6px;
    margin-bottom: 3px;
    background-color: #ccc;
    &:last-child {
      width: 75%;
    }
  }
`

const ConfirmationDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`
export const DialogTitle = styled.div`
  font-size: 18px;
  font-weight: 550;
  text-align: center;
`
export const DialogSubtitle = styled.div`
  color: ${cssvar('mainColor')};
  text-align: center;
  white-space: normal;
`

const DialogActions = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`
