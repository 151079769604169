import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import styled, { css } from 'styled-components'
import { cssvar } from '../../styles/var'

const Cell = ({
  row, column, hide, hideColumnsBreakpoint,
}) => {
  const value = _.get(row, column.dataIndex)
  if (column.render) {
    const renderer = column.dataIndex ? column.render(value, row) : column.render(row)
    return (
      <TD
        align={column.align || 'left'}
        hide={hide}
        hideColumnsBreakpoint={hideColumnsBreakpoint}>
        {renderer}
      </TD>
    )
  }
  return (
    <TD
      align={column.align || 'left'}
      hide={hide}
      hideColumnsBreakpoint={hideColumnsBreakpoint}>
      {value || '-'}
    </TD>
  )
}

const TableContent = ({
  data, columns, rowStyle, onRowClick, sorting, sortingOrder, onChangeSorting, hideColumnsBreakpoint, groupName, customRow, customRowPosition,
}) => (
  <Fragment>
    {customRowPosition === 'top' && <thead>{customRow}</thead>}
    <thead>
      {groupName && (
        <tr>
          <GroupName
            colSpan={columns.length}
            colSpanOnBreakpoint={_.filter(columns, c => !c.hideOnBreakpoint).length}
            hideColumnsBreakpoint={hideColumnsBreakpoint}>
            {groupName}
          </GroupName>
        </tr>
      )}
      <tr>
        {_.map(columns, column => (
          <TH
            key={`th-${column.title}`}
            align={column.align || 'left'}
            sortable={column.sortable}
            sorted={_.isEqual(column.dataIndex, sorting)}
            sorting={sortingOrder}
            hide={column.hideOnBreakpoint}
            hideColumnsBreakpoint={hideColumnsBreakpoint}>
            <span onClick={() => onChangeSorting(column)}>{column.title}</span>
          </TH>
        ))}
      </tr>
    </thead>
    <tbody>
      {customRowPosition === 'top-body' && customRow}
      {_.map(data, (row, index) => (
        <TR key={`tr-${index}`} rowStyle={rowStyle ? rowStyle(row) : undefined} onClick={() => (onRowClick ? onRowClick(row) : undefined)}>
          {_.map(columns, column => (
            <Cell
              key={`td-${column.title}-${index}`}
              row={row}
              column={column}
              hide={column.hideOnBreakpoint}
              hideColumnsBreakpoint={hideColumnsBreakpoint} />
          ))}
        </TR>
      ))}
      {customRowPosition === 'bottom' && customRow}
    </tbody>
  </Fragment>
)

const Table = ({
  data, groupedData, columns, onRowClick, rowStyle, hideColumnsBreakpoint, styledComponent, customRow, customRowPosition = 'bottom', defaultSorting, defaultSortingOrder = 'desc', inverseSortingOrderCycle,
}) => {
  const TableComponent = styledComponent || TableStyled

  const sortableColumns = _.filter(columns, 'sortable')
  const [sorting, setSorting] = useState(defaultSorting || (sortableColumns.length > 0 && sortableColumns[0].dataIndex) || null)
  const [sortingOrder, setSortingOrder] = useState(defaultSortingOrder)

  const sortedData = () => {
    if (!sorting) return data
    const sortedWithoutData = _.filter(data, row => !_.get(row, sorting) || _.get(row, sorting) === '-')
    const sortedWithData = _.difference(data, sortedWithoutData)
    const sortBy = (row) => {
      const currentSorting = _.find(sortableColumns, col => _.isEqual(col.dataIndex, sorting))
      if (currentSorting.customSorting) {
        return currentSorting.customSorting.indexOf(row[currentSorting.dataIndex]) * -1
      }
      return _.get(row, sorting)
    }
    return [...(_.orderBy(sortedWithData, [row => sortBy(row)], [sortingOrder])), ...sortedWithoutData]
  }

  const onChangeSorting = (column) => {
    if (!column.sortable) return null
    if (column.dataIndex === sorting) {
      if (sortingOrder === 'desc') return setSortingOrder('asc')
      return setSortingOrder('desc')
    }
    setSorting(column.dataIndex)
    if (inverseSortingOrderCycle) {
      setSortingOrder('asc')
    } else {
      setSortingOrder('desc')
    }
  }

  const gridTemplate = columns => columns.map((column) => {
    const { width, minWidth, maxWidth } = column
    if (width) return width
    return `minmax(${minWidth ? `${minWidth}px` : 'min-content'}, ${maxWidth ? `${maxWidth}px` : '1fr'})`
  }).join(' ')

  const gridOnBreakpoint = () => {
    if (!hideColumnsBreakpoint) return ''
    return css`
      @media (${hideColumnsBreakpoint}) {
        grid-template-columns: ${gridTemplate(_.filter(columns, c => !c.hideOnBreakpoint))};
      }
    `
  }

  if (groupedData) {
    return (
      <TableComponent
        gridTemplate={gridTemplate(columns)}
        gridOnBreakpoint={gridOnBreakpoint()}>
        {groupedData.map(group => (
          <TableContent
            data={group.data}
            columns={columns}
            rowStyle={rowStyle}
            onRowClick={onRowClick}
            sorting={sorting}
            sortingOrder={sortingOrder}
            onChangeSorting={onChangeSorting}
            hideColumnsBreakpoint={hideColumnsBreakpoint}
            groupName={group.name} />
        ))}
      </TableComponent>
    )
  }

  return (
    <TableComponent
      gridTemplate={gridTemplate(columns)}
      gridOnBreakpoint={gridOnBreakpoint()}>
      <TableContent
        data={sortedData()}
        columns={columns}
        rowStyle={rowStyle}
        onRowClick={onRowClick}
        sorting={sorting}
        sortingOrder={sortingOrder}
        onChangeSorting={onChangeSorting}
        hideColumnsBreakpoint={hideColumnsBreakpoint}
        customRow={customRow}
        customRowPosition={customRowPosition} />
    </TableComponent>
  )
}

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  groupedData: PropTypes.arrayOf(PropTypes.shape()),
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onRowClick: PropTypes.func,
}

Table.defaultProps = {
  data: [],
  columns: [],
}

export default Table

export const TD = styled.td`
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => (align === 'right' ? 'flex-end' : 'flex-start')};
  font-size: 13px;
  padding: 10px 15px;
  border-bottom: 1px solid #e6e6e6;
  line-height: 1.3;
  text-align: ${({ align }) => align || 'left'};
  overflow: hidden;
  transition: .3s;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  @media (max-width: 1440px) {
    font-size: 12px;
    padding: 10px;
  }
  @media (max-width: 640px) {
    padding: 10px 7px;
  }
  ${({ hide, hideColumnsBreakpoint }) => hideColumnsBreakpoint && css`
    @media (${hideColumnsBreakpoint}) {
      ${hide ? 'display: none' : ''};
    }
  `}
`
const TH = styled(TD).attrs(() => ({ as: 'th' }))`
  align-items: flex-end;
  font-size: 12px;
  color: ${cssvar('secondaryTextColor')};
  font-weight: 550;
  text-transform: uppercase;
  overflow: visible;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 3;
  @media (max-width: 1440px) {
    font-size: 10px;
  }
  @media (max-width: 640px) {
    font-size: 9px;
  }
  ${({ sortable }) => sortable && css`
    span {
      font-weight: 700;
      text-decoration: underline;
      text-decoration-style: dashed;
      cursor: pointer;
      transition: color .3s;
    }
  `}
  ${({ sorted, sorting }) => sorted && css`
    span {
      color: ${cssvar('primaryColor')};
      position: relative;
      &:hover { 
        color: ${cssvar('primaryHoverColor')};
      }
      &:before {
        content: '';
        display: inline-block;
        margin-top: 1px;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-top: 6px solid ${cssvar('primaryColor')};
        position: absolute;
        left: -12px;
        top: 50%;
        transform: translateY(-50%);
        transition: .3s;
        ${sorting === 'asc' && css`
          transform: translateY(-50%) rotate(-180deg);
        `}
      }
    }
  `}
  ${({ align, sorted }) => align === 'right' && css`
    ${sorted && 'z-index: 5'};
    ${sorted && 'padding-right: 15px !important'};
    span {
      &:before {
        left: auto;
        right: -12px;
      }
    }
  `}
`
export const TR = styled.tr`
  cursor: pointer;
  &:hover ${TD} {
    background-color: ${cssvar('hoverColor')};
  }
  ${({ rowStyle }) => rowStyle || ''}
`
const GroupName = styled(TH)`
  grid-column-start: 1;
  grid-column-end: ${({ colSpan }) => colSpan + 1};
  &&& {
    padding-top: 40px;
    padding-bottom: 0;
    color: ${cssvar('mainColor')};
    font-size: 16px;
    border-bottom: none;
    position: static;
  }
  ${({ colSpanOnBreakpoint, hideColumnsBreakpoint }) => colSpanOnBreakpoint && hideColumnsBreakpoint && css`
    @media (${hideColumnsBreakpoint}) {
      grid-column-end: ${colSpanOnBreakpoint + 1};
    }
  `}
`

export const TableStyled = styled.table` 
  display: grid;
  grid-template-columns: ${({ gridTemplate }) => gridTemplate};
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  border-collapse: collapse;
  thead, tbody, tr {
    display: contents;
  }
  thead:first-child ${GroupName} {
    padding-top: 0;
  }
  @media (max-width: 1024px) {
    width: auto;
    margin-left: -15px;
    margin-right: -15px;
    ${TD}:first-child {
      padding-left: 15px;
    }
    ${TD}:last-child {
      padding-right: 15px;
    }
  }
  ${({ gridOnBreakpoint }) => gridOnBreakpoint}
`