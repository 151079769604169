import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSVG from 'react-svg'
import VideoIcon from '../../../images/Video.svg'
import CovidIcon from '../../../images/coronavirus.svg'
import DopingIconLight from '../../../images/dopingPillLight.svg'
import ExpandIcon from '../../../images/parent-injury-expand.svg'
import SkipIcon from '../../../images/parent-injury-skip.svg'
import SurgeryIcon from '../../../images/scalpel.png'
import Dialog from '../../Dialog'
import Table from '../../Table'
import RecoveryCell from '../../Table/RecoveryCell'
import DetailedInfo from './DetailedInfo'

import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { hasVideoPermissionSelector } from '../../../redux/selectors/user'
import { cssvar } from '../../../styles/var'
import Formatter from '../../../utils/Formatter'

const InjuriesTable = ({ data, title }) => {
  const { t } = useTranslation()
  const dateFormat = 'DD.MM.YYYY'
  const hasVideoPermission = useSelector(hasVideoPermissionSelector)
  const [selectedParentInjuries, setParentInjury] = useState([])
  const [modal, setModal] = useState({})
  const closeDetailedInfo = () => {
    setModal({ ...modal, open: false })
  }
  const openDetailedInfo = (injury) => {
    if (injury.has_children) {
      return setParentInjury(_.xor(selectedParentInjuries, [injury.id]))
    }
    return setModal({ injury, open: true })
  }

  const columns = [
    {
      width: 'min-content',
      render: (injury) => {
        const hasMedia = injury.media
          && Array.isArray(injury.media)
          && !!injury.media.find(({ mime_type }) => mime_type)

        let overriddenColors

        const findIcon = () => {
          if (injury.has_children) {
            if (_.includes(selectedParentInjuries, injury.id)) {
              return SkipIcon
            }
            return ExpandIcon
          }
          if (hasVideoPermission) {
            return VideoIcon
          }

          return null
        }

        const icon = findIcon()
        return (
          <InjuryActionButton
            disabled={!hasMedia && !injury.has_children}
            overriddenColors={overriddenColors}
          >
            <ReactSVG src={icon} />
          </InjuryActionButton>
        )
      },
    },
    {
      title: t('injury-type'),
      width: 'minmax(min-content, 4fr)',
      render: (injury) => {
        if (injury.other_event) {
          const isDoping = injury.injury_category.includes('doping')

          if (isDoping) {
            return <InjuryLabel icon={DopingIconLight} color="#FF8B18">{t(`injury-type-${injury.injury_type}`)}</InjuryLabel>
          }
        }

        if (injury.surgery === 1) {
          return (
            <InjuryLabel icon={SurgeryIcon}>{t(`injury-type-${injury.injury_type}`)}</InjuryLabel>
          )
        }
        if (_.includes(injury.description, 'Covid-19')) { return <InjuryLabel icon={CovidIcon}>Covid-19</InjuryLabel> }
        return (
          <NoWrapInjuryLabel
            // nowrap={_.includes(t(`injury-type-${injury.injury_type}`), ' | ')}
            nowrap
            maxWidth="100px"
            smMaxWidth="80px"
          >
            {t(`injury-type-${injury.injury_type}`)}
          </NoWrapInjuryLabel>
        )
      },
    },
    {
      title: t('injury-part'),
      dataIndex: 'injuried_part',
      display: !title,
      width: 'minmax(min-content, 1fr)',
      render: (part, injury) => {
        if (injury.other_event) {
          return '-'
        }
        return (
          <NoWrapInjuryLabel nowrap maxWidth="70px" smMaxWidth="65px">
            {t(`injury-part-${part}`)}
          </NoWrapInjuryLabel>
        )
      }
      ,
    },
    {
      title: t('injury-side'),
      display: !title,
      hideOnBreakpoint: true,
      width: 'minmax(min-content, 1fr)',
      render: (injury) => {
        if (
          _.includes(['pelvis', 'chest'], injury.injuried_part)
          && injury.side
          && injury.side === 'both'
        ) { return '-' }
        if (injury.side && injury.side === 'both') return `${t('injury-left')}/${t('injury-right')}`
        if (injury.side) return t(`injury-${injury.side}`)
        return '-'
      },
    },
    {
      title: t('injury-contact'),
      dataIndex: 'trauma',
      hideOnBreakpoint: true,
      width: 'minmax(min-content, 1fr)',
      render: trauma => (
        <NoWrapInjuryLabel nowrap>
          {trauma && trauma !== 'na' ? t(`injury-contact-${trauma}`) : '-'}
        </NoWrapInjuryLabel>
      ),
    },
    {
      title: t('injury-injured'),
      dataIndex: 'date',
      sortable: true,
      width: 'minmax(min-content, 1fr)',
      render: (date, injury) => moment(injury.has_parent ? injury.actual_date : date).format(dateFormat),
    },
    {
      title: t('injury-return'),
      hideOnBreakpoint: true,
      width: 'minmax(min-content, 1fr)',
      render: (injury) => {
        const recoveryDays = injury.has_parent ? injury.actual_recovery_days : injury.recovery_days
        return recoveryDays !== 0 && recoveryDays !== '0'
          ? (injury.dateEnd && moment(injury.dateEnd).format(dateFormat))
          || (injury.has_children ? '-' : t(Formatter.formatExpectedDowntime(injury)))
          : t('injury-unknown')
      },
    },
    {
      title: t('injury-recovery'),
      dataIndex: 'recovery_days',
      sortable: true,
      width: 'minmax(min-content, 1fr)',
      render: (days, injury) => (
        <RecoveryCell days={injury.has_parent ? injury.actual_recovery_days : days} />
      ),
    },
    {
      title: t('injury-missed-match'),
      dataIndex: 'missed_matches',
      sortable: true,
      hideOnBreakpoint: true,
      width: 'minmax(min-content, 1fr)',
      render: (missedMatches, injury) => {
        const matches = injury.has_parent ? injury.actual_missed_matches : missedMatches
        return matches === null ? t('injury-nd') : matches
      },
    },
  ]

  const getColumns = () => columns.filter(({ display = true }) => display)

  const getData = () => {
    const dataWithoutChildInjuries = _.filter(data, injury => !injury.parent_injury_id)
    const showData = dataWithoutChildInjuries
    selectedParentInjuries.map(injuryId => showData.splice(
      _.indexOf(
        dataWithoutChildInjuries,
        _.find(dataWithoutChildInjuries, injury => injury.id === injuryId),
      ) + 1,
      0,
      _.filter(data, injury => injury.parent_injury_id === injuryId),
    ))
    return _.flattenDeep(showData).map((injury) => {
      const parent = _.find(data, i => i.id === injury.parent_injury_id)
      return {
        ...injury,
        has_children: !!_.find(data, i => i.parent_injury_id === injury.id),

        // this shit is to get around data sorting. put parent sortable data to a child to stick children to a parent and add actual data for displaying. it is very bad, I know, but it is less horrifying than to mess around in the table component.
        date: parent ? parent.date : injury.date,
        recovery_days: parent ? parent.recovery_days : injury.recovery_days,
        missed_matches: parent ? parent.missed_matches : injury.missed_matches,
        ...(parent
          ? {
            has_parent: true,
            actual_date: injury.date,
            actual_recovery_days: injury.recovery_days,
            actual_missed_matches: injury.missed_matches,
          }
          : {}),
      }
    })
  }

  const rowStyle = injury => css`
      td {
        transition: none;
        ${modal.open
    && modal.injury
    && modal.injury.id === injury.id
    && css`
          color: ${cssvar('oldPrimaryColor')};
          transition: color 0.3s ease-in;
        `}
        ${!modal.open
    && modal.injury
    && modal.injury.id === injury.id
    && css`
          transition: color 5s ease-in;
        `}
      }
      ${injury.ongoing
    && css`
        td {
          background: ${Formatter.hexToRgba(cssvar('injuryColor'), 0.05)};
        }
        &&&:hover td {
          background: ${Formatter.hexToRgba(cssvar('injuryColor'), 0.08)};
        }
      `}
      ${_.isNumber(injury.parent_injury_id)
    && css`
        td {
          background: ${cssvar('background')};
          &:first-child {
            box-shadow: inset 3px 0px 0 0px #327bb5;
            border-bottom: none;
          }
        }
        &&&:hover td {
          background: #f1f1f1;
        }
      `}
      ${_.includes(selectedParentInjuries, injury.id)
    && css`
        ${InjuryActionButton} {
          path,
          &:hover path {
            fill: white;
          }
        }
        ${InjuryLabel} {
          color: white;
        }
        td,
        &&&:hover td {
          background: #327bb5;
          color: white;
          font-weight: 500;
          transition: color 0s;
          border-bottom-color: #327bb5;
          &:first-child {
            border-top-left-radius: 4px;
          }
          &:last-child {
            border-top-right-radius: 4px;
          }
        }
      `}
    `
  return (
    <Fragment>
      <TableWrapper>
        {title && <TableTitle>{title}</TableTitle>}
        <Table
          data={getData()}
          columns={getColumns()}
          defaultSorting='date'
          rowStyle={rowStyle}
          hideColumnsBreakpoint='max-width: 640px'
          onRowClick={openDetailedInfo}
        />
      </TableWrapper>

      <Dialog
        open={modal.open}
        title={modal.injury && modal.injury.other_event ? t('other-event-details') : t('injury-details')}
        fullscreenOnPhone
        onClose={closeDetailedInfo}
      >
        {modal.injury && <DetailedInfo injury={modal.injury} />}
      </Dialog>
    </Fragment>
  )
}

InjuriesTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  coverageStatus: PropTypes.string,
}

InjuriesTable.defaultProps = {
  data: [],
}

export default InjuriesTable

const TableWrapper = styled.div`
  position: relative;
`
const TableTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  position: sticky;
  left: 0;
  span {
    color: ${cssvar('injuryColor')};
  }
`
const InjuryActionButton = styled.div`
  width: 18px;
  height: 18px;
  margin-left: 15px;
  cursor: pointer;
  svg {
    width: 100%;
    height: 100%;
  }
  path {
    fill: ${props => (props.overriddenColors && props.overriddenColors.mainColor) || cssvar('mainColor')};
    transition: 0.3s;
  }
  &:hover path {
    fill: ${props => (props.overriddenColors && props.overriddenColors.hoverColor) || cssvar('primaryColor')};
  }
  ${({ disabled }) => disabled
    && css`
      opacity: 0.1;
      pointer-events: none;
    `}
  @media (max-width: 1440px) {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
  @media (max-width: 1024px) {
    width: 12px;
    height: 12px;
    margin-left: 0;
  }
`
const InjuryLabel = styled.span`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: ${props => props.color || cssvar('injuryColor')};
  font-weight: 550;
  line-height: 1;
  white-space: nowrap;
  &:before {
    content: '';
    flex: 0 0 auto;
    width: 14px;
    height: 14px;
    margin-top: -1px;
    margin-right: 6px;
    background-image: url(${props => props.icon});
    background-size: cover;
  }
  @media (max-width: 1600px) {
    white-space: normal;
  }
  @media (max-width: 600px) {
    font-weight: 500;
    font-size: 90%;
    &:before {
      width: 12px;
      height: 12px;
    }
  }
`
const NoWrapInjuryLabel = styled.div`
  white-space: ${props => (props.nowrap ? 'nowrap' : 'normal')};
  overflow: ${props => (props.nowrap ? 'hidden' : '----')};
  text-overflow: ${props => (props.nowrap ? 'ellipsis' : '----')};
  @media (max-width: 600px) {
    max-width: ${props => props.maxWidth};
  }
  @media (max-width: 400px) {
    max-width: ${props => (props.smMaxWidth ? props.smMaxWidth : props.maxWidth)};
  }
`
