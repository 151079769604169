import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { continentImages } from '../../config/continentImages'
import { PLAYER_ND_IMAGE_URL, toShortDate } from '../../helpers'
import WorldIcon from '../../images/World.svg'
import { hasProfileEditPermissionSelector } from '../../redux/selectors/user'
import { cssvar } from '../../styles/var'
import Formatter from '../../utils/Formatter'
import { languageToTranslatedDescriptionLanguage } from '../Sections/Injury/DetailedInfo'
import PDFGenerator from '../Toolbar/PDFGenerator'
import Social from './Social'
import Star from './Star'

const Detail = ({
  data: {
    social_data = {},
    name,
    image,
    favourite,
    meta = {},
    type,
    injuries = [],
    tags = [],
    tag,
    favourite_settings = {},
    drilldown_ids,
  },
}) => {
  const { t, i18n } = useTranslation()

  const hasProfileEditPermission = useSelector(hasProfileEditPermissionSelector)

  const tagsArr = tags && tags.length > 0 ? (_.isArray(tags) ? tags : tags.split(',')) : []
  const team = _.filter(tagsArr, tag => tag.doc_type === 'team').map(tag => ({
    id: tag.tag,
    label: (tag.meta && tag.meta.common_name) || tag.label,
    image: tag.image,
  }))[0]

  const displayFullNameAsHeader = () => {
    if (type !== 'profile') return false
    const { lastname } = meta
    if (_.last(_.split(name, ' ')) === lastname) return true
    return false
  }

  const renderMainName = () => {
    if (!meta) return name
    const { firstname, lastname } = meta
    if (displayFullNameAsHeader()) return `${firstname} ${lastname}`
    return name
  }

  const renderDescText = () => {
    if (type === 'profile') {
      return (
        <Fragment>
          {team && (
            <Team to={`/explore/${team.id}`}>
              {team.image && <img src={team.image} alt='' />}
              {team.label}
            </Team>
          )}
          {meta.role && <DescForMobile hideSeparator={!team}>{t(meta.role)}</DescForMobile>}
          {meta.birthdate && (
            <DescForMobile>{Formatter.formatDate(toShortDate(meta.birthdate))}</DescForMobile>
          )}
        </Fragment>
      )
    }
    if (type === 'extra') {
      return (
        <Fragment>
          {team && (
            <Fragment>
              <Team to={`/explore/${team.id}`}>
                {team.image && <img src={team.image} alt='' />}
                {team.label}
              </Team>
              <br />
            </Fragment>
          )}
          <DescForNotMobile>{`${t(`d_${meta.type}`)}${meta.description ? ` | ${t(`ed_${meta.description}`)}` : ''}`}</DescForNotMobile>
          <DescForMobile>{`${t(`d_${meta.type}`)}`}</DescForMobile>
        </Fragment>
      )
    }
    if (type === 'team') return t('team-singular')
    return t(`d_${type}`)
  }

  const renderEdit = () => {
    if (!hasProfileEditPermission) return null
    if (!drilldown_ids || !['profile', 'extra'].includes(type)) return null

    const baseUrl = 'https://hq.noisefeed.com/admin'
    let href

    if (type === 'profile') {
      const id = drilldown_ids.player_id
      href = `${baseUrl}/profiles/redirect-to-edit/${id}`
    } else {
      const id = tag.substr(2)
      const prefix = tag.substr(0, 2)
      switch (prefix) {
        case 'p_':
          href = `${baseUrl}/players/${id}/edit`
          break
        default:
          href = `${baseUrl}/extras/${id}/edit`
          break
      }
    }


    return (
      <PenWrapper href={href} target='_blank'>
        <FontAwesomeIcon icon={['fas', 'pencil']} />
      </PenWrapper>
    )
  }

  const currentLang = i18n.languages[0].toLowerCase()
  const targetLang = currentLang === 'pt-pt' || currentLang === 'fr-fr' ? 'en-gb' : currentLang
  const renderPlayerInfo = () => {
    if (type !== 'profile') return null
    const {
      firstname, lastname, role, foot, height, weight, birthdate, birthplace,
    } = meta

    const currentInjuries = _.filter(
      injuries,
      injury => injury.ongoing && !injury.other_event && !_.includes(['-', '.'], injury.description),
    )

    const currentOtherEvents = _.filter(
      injuries,
      injury => injury.ongoing && injury.other_event && !_.includes(['-', '.'], injury.description),
    )
    return (
      <PlayerInfo>
        {!displayFullNameAsHeader() && (
          <Fragment>
            <InfoItemName>{t('Full name')}</InfoItemName>
            <InfoItemValue>{`${firstname} ${lastname}`}</InfoItemValue>
          </Fragment>
        )}
        <InfoItemName>{t('role')}</InfoItemName>
        <InfoItemValue>{t(role)}</InfoItemValue>
        {foot && (
          <Fragment>
            <InfoItemName>{t('foot')}</InfoItemName>
            <InfoItemValue>{t(foot)}</InfoItemValue>
          </Fragment>
        )}
        {height > 0 && weight > 0 && (
          <Fragment>
            <InfoItemName>{t('Body')}</InfoItemName>
            <InfoItemValue>
              {height ? `${height}cm` : ''}
              {' '}
              /
              {weight ? `${weight}kg` : ''}
            </InfoItemValue>
          </Fragment>
        )}
        <InfoItemName>{t('Born')}</InfoItemName>
        {meta.birthdate && (
          <InfoItemValue>
            {_.join(_.compact([Formatter.formatDate(toShortDate(birthdate)), birthplace]), ', ')}
          </InfoItemValue>
        )}
        {currentOtherEvents.length > 0 && (
          <Fragment>
            <InfoItemName>{t('Other events')}</InfoItemName>
            <InfoItemValue other_event>
              {currentOtherEvents.map((other_event, i) => (
                <div key={`other_event${i}`}>
                  {other_event.translated_description
                    ? other_event.translated_description[
                    languageToTranslatedDescriptionLanguage[targetLang]
                    ]
                    : other_event.description}
                  {_.indexOf(currentOtherEvents, other_event) === currentOtherEvents.length - 1 ? '' : ';'}
                </div>
              ))}
            </InfoItemValue>
          </Fragment>
        )}
        {currentInjuries.length > 0 && (
          <Fragment>
            <InfoItemName>{t('Injury')}</InfoItemName>
            <InfoItemValue injury>
              {currentInjuries.map((inj, i) => (
                <div key={`injury${i}`}>
                  {inj.translated_description
                    ? inj.translated_description[
                    languageToTranslatedDescriptionLanguage[targetLang]
                    ]
                    : inj.description}
                  {_.indexOf(currentInjuries, inj) === currentInjuries.length - 1 ? '' : ';'}
                </div>
              ))}
            </InfoItemValue>
          </Fragment>
        )}
      </PlayerInfo>
    )
  }

  return (
    <Fragment>
      <DetailContainer>
        <MainInfo>
          <Image
            src={(continentImages[name] ? continentImages[name] : image) || WorldIcon}
            playerPhoto={type === 'profile'}
            onError={(e) => {
              if (type === 'profile') {
                e.target.src = PLAYER_ND_IMAGE_URL
              } else {
                e.target.src = WorldIcon
              }
            }}
          />
          <NameAndDesc>
            <Name>
              <span>{renderMainName()}</span>
              {renderEdit()}
              <StarWrapper>
                <Star favourite={favourite} tag={tag} details={favourite_settings} />
              </StarWrapper>
            </Name>
            {tag && <Description>{renderDescText()}</Description>}
          </NameAndDesc>
        </MainInfo>
        {renderPlayerInfo()}
        {_.size(social_data) > 0 && <Social data={social_data} />}
        {['profile', 'team', 'competition', 'national_team'].includes(type) && (
          <PDFGeneratorWrapper>
            <PDFGenerator id={tag} />
          </PDFGeneratorWrapper>
        )}
      </DetailContainer>
    </Fragment>
  )
}

Detail.propTypes = {
  data: PropTypes.shape(),
}
Detail.defaultProps = {
  data: {},
}

export default Detail

export const DetailContainer = styled.div`
  padding: 15px;
  padding-top: 15px;
  position: relative;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${cssvar('mainColor')};
    border-bottom: 1px solid rgba(255,255,255, .15);
    color: #fff;
  }
`
export const MainInfo = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`
export const Image = styled.img`
  flex: 0 0 auto;
  height: 40px;
  margin-right: 15px;
  ${props => props.playerPhoto && css`
    height: 42px;
    margin-top: -3px;
    margin-right: 10px;
  `};
  @media (max-width: 1024px) {
    height: 60px;
    margin: 0;
    background-color: #fff;
    border-radius: 4px;
    border: 2px solid rgba(255,255,255, .5);
  }
`
export const NameAndDesc = styled.div`
  flex: 1 1 auto;
`
export const Name = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 18px;
  line-height: 20px;
  font-weight: 650;
  span {
    flex: 1 1 auto;
  }
  @media (max-width: 1024px) {
    margin-top: 10px;
    text-align: center;
  }
`
const StarWrapper = styled.div`
  flex: 0 0 auto;
  margin-left: 10px;
  @media (max-width: 1024px) {
    margin: 0;
    position: absolute;
    top: 15px;
    right: 15px;
  }
`
export const Description = styled.div`
  margin-top: 4px;
  font-size: 12px;
  line-height: 1;
  font-weight: 420;
  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    font-size: 13px;
  }
`
const DescForNotMobile = styled.span`
  display: block;
  @media (max-width: 1024px) {
    display: none;
  }
`
const DescForMobile = styled.span`
  display: none;
  @media (max-width: 1024px) {
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    ${props => !props.hideSeparator && css`
    &:before {
      content: '';
      display: inline-block;
      width: 3px;
      height: 3px;
      margin-right: 10px;
      background-color: white;
      border-radius: 50%;
    }
    `} 
  }
`
const Team = styled(Link)`
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  img {
    height: 16px;
    margin-right: 6px;
  }
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 1024px) {
    color: #fff;
    font-weight: 600;
    text-decoration: underline;
  }
`
const PlayerInfo = styled.div`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-gap: 4px 15px;
  margin-top: 15px;
  font-size: 13px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    max-width: 300px;
    grid-gap: 0;
    margin: 0;
  }
`
const InfoItemName = styled.div`
  font-weight: 550;
  white-space: nowrap;
  @media (max-width: 1024px) {
    display: none;
  }
`
const InfoItemValue = styled.div`
  ${props => (props.injury || props.other_event) && css`
    color: ${cssvar('injuryColor')};
    font-weight: 480;
  `}
  @media (max-width: 1024px) {
    display: none;
    ${props => (props.injury || props.other_event) && css`
      display: block;
      margin-top: 15px;
      color: #f54938;
      font-weight: 600;
      text-align: center;
    `}
  }
`

const PenWrapper = styled.a`
  text-decoration: none;
  color: #000 !important;
  svg {
    width: 15px !important;
    transition: 0.3s;
    &:hover {
      color: #f5840b;
    }
  }
`

const PDFGeneratorWrapper = styled.div`
  margin-top: 15px;
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
`