import { Avatar, Col, Row } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Container, H2, Main } from '../styledComponents'
import InfoRow from './InfoRow'

const Biography = ({ data }) => {
  const { t, i18n } = useTranslation()
  const {
    image,
    bio,
    meta: {
      firstname,
      middlename,
      lastname,
      birthdate,
      birthplace,
      height,
      foot,
      role,
      weight,
    } = {},
    tags: [{ label: teamLabel }] = [{}],
  } = data

  moment.locale(i18n.language)

  return (
    <Container>
      <BioWrapper gutter={20}>
        <AvatarInfoWrapper>
          <AvatarWrapper type="flex" justify="center" align="middle">
            <Col lg={{ span: 24 }} xl={{ span: 8 }}>
              <AvatarResponsive src={image} />
            </Col>
            <Col lg={{ span: 24 }} xl={{ span: 16 }}>
              <H1>{`${firstname} ${middlename || ''} ${lastname}`}</H1>
              <TeamLabel>{teamLabel}</TeamLabel>
            </Col>
          </AvatarWrapper>

          <BasicInfo>
            <H2>{t('basic-info')}</H2>
            <InfoRow label="birth-date">
              {moment(birthdate).format('D MMMM YYYY')}
            </InfoRow>
            <InfoRow label="birth-place">{birthplace}</InfoRow>
            {height ? <InfoRow label="height">{`${height} cm`}</InfoRow> : null}
            {weight ? <InfoRow label="weight">{`${weight} kg`}</InfoRow> : null}
            <InfoRow label="foot">{t(foot)}</InfoRow>
            <InfoRow label="role">{t(role)}</InfoRow>
          </BasicInfo>

        </AvatarInfoWrapper>
        <MainWrapper span={18}>
          <Main>
            <H2>{t('biography')}</H2>
            <div dangerouslySetInnerHTML={{ __html: bio }} />
            <p
              style={{ paddingTop: '1em', fontSize: '0.8em' }}
              dangerouslySetInnerHTML={{
                __html:
                  t('wikipedia-citing', { name: `${firstname} ${middlename || ''} ${lastname}` }),
              }} />
          </Main>
        </MainWrapper>
      </BioWrapper>
    </Container>
  )
}

Biography.propTypes = {
  data: PropTypes.shape(),
}

Biography.defaultProps = {
  data: {},
}

export default Biography

const BioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 15px;
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    column-gap: 0;
    row-gap: 15px;
  }
`

const AvatarInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: min(200px, 30%);
  @media (max-width: 1024px) {
    padding: 0 15px;
  }
`

const AvatarWrapper = styled(Row)`
  @media (max-width: 1024px) {
    display: none;
  }
`

const AvatarResponsive = styled(Avatar)`
  background-color: white;
  padding: 16px 8px 0;
  width: 100%;
  height: 100%;
  max-width: 100px;
  max-height: 100px;
`
const TeamLabel = styled('p')`
  color: #1890ff;
  font-weight: 700;
`
const BasicInfo = styled(Col)`
  margin-top: 2em;
  padding: 1em;
  background-color: white;
  @media (max-width: 1024px) {
    margin-top: 0;
  }
`

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    padding: 0 15px;
  }
`

const H1 = styled('h1')`
  font-size: 1.7em;
  font-weight: 700;
  margin: 0;
`